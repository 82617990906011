<template>
  <div  class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white login-signin-on"
      id="kt_login"
    >
      <div class="side_image">
        <img src="/media/side_image.png" />
      </div>
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
            >
            <div class="CodeInput">
              <div v-if="validLogin == true">
                <div v-if="API_ERROR == 'code_not_valid'" class="error_mesage">
                  <p>الرمز غير صحيح برجاء إعادة المحاوله</p>
                </div>
                <div v-if="API_ERROR == 'err'" class="error_mesage">
                  <p>{{ API_ERROR_tex }}</p>
                </div>
                <h5>
                  لقد قمنا بإرسال رمز تأكيد الى هاتفك المحمول برجاء ادخال الرمز
                  للإستمرار
                </h5>
                <div class="form-group m-0">
                  <CodeInput
                    v-on:change="Saveverfication_code"
                    :loading="false"
                    class="input"
                    :fields="4"
                  />
                </div>
              </div>
              <div v-if="validLogin == false">
                <h5 class="erorr_title">
                  عفوا انت تحاول الوصل الى إجتماع لست مدعو الية او اصبح غير
                  موجود
                </h5>
              </div>
            </div>

              <div class="form-group">
                <v-btn

                  ref="kt_login_signin_submit"
                  class="btn btn_brimary font-weight-bolder font-size-h6 px-15 py-4 my-3"
 @click="verfiy_log_code()"
                >
                 إرسال
                </v-btn>
              </div>
            </form>
          </div>
        </div>

        <!--begin::Content footer-->
        <div
          class="login_footer d-flex text-center justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
        >
          جميع الحقوق محفوظه لدى وزارة الاتصالات وتقنية المعلومات 2021
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column bgi-no-repeat bgi-size-cover bgi-position-y-bottom bgi-position-x-center flex-row-auto"
        :style="{ backgroundImage: `url(/media/Path1.svg` }"
      >
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat position-relative bgi-size-cover bgi-position-y-bottom bgi-position-x-center"
          :style="{ backgroundImage: `url(/media/tower.png)` }"
        >
          <div class="slogin_container">
            <h3>
              تمكين المملكة العربية السعودية , اقتصاديا<br />
              ومجتمعا ووطنا, من استثمار الفرص التى يوفرها<br />
              عنصر الرقمنه والوصول بالمملكة لطليعة الدول<br />
              المبتكرة
            </h3>
          </div>
          <div class="green_path">
            <img src="/media/Path2.svg" />
          </div>
        </div>
      </div>
      <!--begin::Aside-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
// import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
// import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
// import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
// import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import CodeInput from "vue-verification-code-input";

import auth from "../../../../http/requests/auth";
export default {
  name: "login-1",
 data() {
    return {
      validLogin: null,
      verfication_code: null,
      API_ERROR: null,
      API_ERROR_tex: null,
      base_URL: "",
    };
  },
   mounted() {},
  components: { CodeInput },
  methods: {
    Saveverfication_code(value) {
      this.verfication_code = value;
    },
    easy_user_login() {
      this.$vs.loading();
      const form = {
        slug: this.$route.params.slug,
        log_token: this.$route.params.log_token,
        user_group: this.$route.params.user_group,
      };
      auth
        .easy_user_login(form)
        .then((res) => {
          this.validLogin = true;
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.validLogin = false;
          this.$vs.loading.close();
        });
    },
    verfiy_log_code() {
      this.$vs.loading();
      const form = {
        slug: this.$route.params.slug,
        log_token: this.$route.params.log_token,
        user_group: this.$route.params.user_group,
        log_code: this.verfication_code,
      };
      auth
        .verfiy_log_code(form)
        .then((res) => {
          window.localStorage.setItem(
            "User_data",
            JSON.stringify(res.data.data)
          );
          window.localStorage.setItem("id_token", res.data.token);
          window.location.reload();
          // this.$router.push(`/mt/${this.$route.params.slug}`)

          this.$vs.loading.close();
        })
        .catch((err) => {
          if (err.response.data.error == "The selected log code is invalid.") {
            this.API_ERROR = "code_not_valid";
          } else {
            this.API_ERROR = "err";
            this.API_ERROR_tex = err.response.data.error;
          }
          this.$vs.loading.close();
        });
    },
  },
  created() {
    this.base_URL = window.location.origin;
    var token = window.localStorage.getItem("id_token");
    if (token) {
      window.location.href = `/#/mt/${this.$route.params.slug}`;
    } else {
      this.easy_user_login();
    }
  },
};
</script>
<style scoped lang="scss">
//  #1bb04e: #1bb04e;
.CodeInput {
  display: block;
  text-align: center;
  margin: auto;
  h5 {
    font-size: 20px;
    color:  #1bb04e;
    font-family: "Tajawal";
  }
  .erorr_title {
    color: #dc3239;
  }
}
.react-code-input-container {
  background: none;
  border: none;
  box-shadow: none;
  margin: auto;
  direction: ltr;
  width: 100% !important;
  margin: auto;
  text-align: center;
  height: 60px;
  text-align: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;

  input {
    margin-right: 20px;
    border: 1px solid #dfdfdf !important;
    border-radius: 5px !important;
  }
}

</style>